
<style scoped lang="less">
.Taxation {
  background: #fff;
  box-shadow: @shadow;
  padding: 20px;
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  .el-input,
  .el-select {
    max-width: 400px;
  }
  .tax-submit-time {
    font-weight: bold;
    color: #ff0000;
    font-size: 18px;
  }
}
</style>
<style lang="less">
.Taxation {
  .edit-message {
    .el-form-item__label {
      font-weight: bold;
    }
    .el-form-item__content {
      span {
        font-weight: bold;
        color: #ff0000;
        font-size: 16px;
      }
    }
  }
}
</style>
<template>
  <div class="Taxation">
    <div class="header">
      <div class="stu-module-title">报税信息</div>
      <div class="back">
        <el-button type="primary" @click="back">返回</el-button>
      </div>
    </div>
    <el-form
      ref="taxForm"
      :model="formData"
      :rules="rules"
      size="medium"
      label-width="120px"
    >
      <el-form-item label="填表说明" class="edit-message">
        <span>842582</span>
      </el-form-item>
      <el-form-item label="是否在职" prop="isJob">
        <el-select v-model="formData.isJob" placeholder="请选择" clearable>
          <el-option label="是" :value="1"></el-option>
          <el-option label="否" :value="0"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="父母姓名1" prop="fmName1">
        <el-input
          v-model="formData.fmName1"
          placeholder="请输入"
          clearable
        ></el-input>
      </el-form-item>
      <el-form-item label="父母证件类型1" prop="fmCardType1">
        <el-select
          v-model="formData.fmCardType1"
          placeholder="请选择"
          clearable
        >
          <el-option
            v-for="(item, index) in cardTypeOptions"
            :key="index"
            :label="item.label"
            :value="item.value"
            :disabled="item.disabled"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="父母证件号码1" prop="fmCardID1">
        <el-input
          v-model="formData.fmCardID1"
          placeholder="请输入"
          :maxlength="18"
          show-word-limit
          clearable
        ></el-input>
      </el-form-item>
      <el-form-item label="父母姓名2" prop="fmName2">
        <el-input
          v-model="formData.fmName2"
          placeholder="请输入父母姓名2"
          clearable
        >
        </el-input>
      </el-form-item>
      <el-form-item label="父母证件类型2" prop="fmCardType2">
        <el-select
          v-model="formData.fmCardType2"
          placeholder="请选择"
          clearable
        >
          <el-option
            v-for="(item, index) in cardTypeOptions"
            :key="index"
            :label="item.label"
            :value="item.value"
            :disabled="item.disabled"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="父母证件号码2" prop="fmCardID2">
        <el-input
          v-model="formData.fmCardID2"
          placeholder="请输入"
          clearable
        ></el-input>
      </el-form-item>
      <el-form-item>
        <span class="tax-submit-time"
          >报税提交时间：2020-10-30 至 2020-11-05</span
        >
      </el-form-item>
      <!-- <el-form-item>
        <el-button type="primary" @click="submitForm">提交</el-button>
        <el-button @click="resetForm">重置</el-button>
      </el-form-item> -->
    </el-form>
  </div>
</template>
<script>
import { STU_HOME_ROUTE } from "@/tools/const";
export default {
  components: {},
  props: [],
  data() {
    return {
      formData: {
        isJob: 0,
        fmName1: "",
        fmCardType1: 1,
        fmCardID1: "",
        fmName2: "",
        fmCardType2: 1,
        fmCardID2: undefined,
      },
      rules: {
        isJob: [],
        fmName1: [],
        fmCardType1: [],
        fmCardID1: [],
        fmName2: [],
        fmCardType2: [],
        fmCardID2: [],
      },
      cardTypeOptions: [
        {
          label: "居民身份证",
          value: 1,
        },
        {
          label: "香港特区护照/身份证明 ",
          value: 2,
        },
        {
          label: "澳门特区护照/身份证明",
          value: 3,
        },
        {
          label: "台湾居民来往大陆通行证",
          value: 4,
        },
        {
          label: "境外永久居住证",
          value: 5,
        },
        {
          label: "护照",
          value: 6,
        },
        {
          label: "港澳台居民居住证",
          value: 7,
        },
      ],
    };
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {
    submitForm() {
      this.$refs["taxForm"].validate((valid) => {
        if (!valid) return;
        // TODO 提交表单
      });
    },
    resetForm() {
      this.$refs["taxForm"].resetFields();
    },

    back() {
      this.$store.dispatch("set_stu_hm_route_switch", STU_HOME_ROUTE.HOME);
    },
  },
};
</script>
