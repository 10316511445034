

<style lang="less">
.Standby {
  background: #fff;
  box-shadow: @shadow;
  padding: 20px;
  margin-bottom: 20px;
  .stu-module-header {
    padding-bottom: 10px;
    border-bottom: 1px solid #f7faff;
    .question {
      cursor: pointer;
    }
  }
  .stu-btn-group {
    text-align: center;
    margin-top: 20px;
    .el-button {
      position: relative;
      transition: bottom 0.2s;
      bottom: 0;
      width: 80%;
      padding: 12px 0;
      font-size: 18px;
      background: #f2f2f2;
      color: @fontColor;
      border-radius: 2px;
      border: 0;
      margin: 0 auto 15px;
      span {
        font-weight: bold;
      }

      &:hover {
        bottom: 5px;
      }
    }
  }
}
</style>
<template>
  <div class="Standby">
    <div class="stu-module-header">
      <div class="stu-module-title">学习必备</div>
      <el-tooltip class="item" effect="dark" content="暂未开发" placement="top">
        <i class="el-icon-question question"></i>
      </el-tooltip>
    </div>
    <div class="stu-btn-group">
      <el-button @click="onSearch" icon="el-icon-search">百问百答</el-button>
      <el-button @click="onRules" icon="el-icon-bank-card">规章制度</el-button>
      <el-button @click="onMethod" icon="el-icon-reading">学费方法</el-button>
      <el-button @click="onPay" icon="el-icon-money">缴费说明</el-button>
    </div>
  </div>
</template>

<script>
import { STU_HOME_ROUTE } from "@/tools/const";
export default {
  name: "standby",
  data() {
    return {};
  },
  methods: {
    onSearch() {
      this.$message("暂未开发");
    },
    onRules() {
      this.$message("暂未开发");
      return;
      this.$store.dispatch("set_stu_hm_route_switch", STU_HOME_ROUTE.RULES);
    },
    onMethod() {
      this.$message("暂未开发");
    },
    onPay() {
      this.$message("暂未开发");
    },
  },
};
</script>