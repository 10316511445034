<style scoped lang="less">
.Student-Home {
  .row {
    // min-width: 1000px;
    margin-bottom: 20px;
  }
}
</style>
<template>
  <div class="Student-Home" v-loading="loading">
    <el-row :gutter="16" v-if="routeSwitch === home_route.HOME">
      <el-col :sm="24" :md="17" class="md24_marginBottom">
        <!-- 用户信息 -->
        <el-row :gutter="16" class="row">
          <el-col class="mobilebg"
            :sm="24"
            :md="studentCourseStat ? 16 : 24"
            :lg="studentCourseStat ? 18 : 24"
          >
            <user-info />
          </el-col>
		  <el-col :md="24" :lg="14" class="md24_marginBottom pchide">
		  	<div v-for="(item, index) in menuList">
		  		<div :index="item.path" :key="index" @click="toMenu(item)" style="width: 85px;height: 65px;float: left;text-align: center;margin:5px 5px 5px 5px">
		  		  <i v-if="item.icon" :class="item.icon" style="width: 35px;margin: 0px auto;color: #307ef3;"></i>
		  		  <div v-else-if="item.svg" class="svg-div" style="width: 35px;margin: 0px auto;">
		  		    <svg-icon :name="item.svg" style="width: 30px; height:30px;color: #307ef3;"></svg-icon>
		  		  </div>
		  		  <span
		  		    slot="title" style="font-size: 12px;"
		  		    :style="!item.svg && !item.icon ? { paddingLeft: '20px' } : {}"
		  		    >{{ item.title }}</span
		  		  >
		  		  <!-- <el-badge
		  		    v-if="item.count && badge > 0"
		  		    :value="badge"
		  		    class="stu_badge"
		  		  ></el-badge> -->
		  		</div>
		  		</div>
		  </el-col>
          <!-- 课程统计 -->
          <el-col :sm="24" :md="8" :lg="6" v-if="studentCourseStat" class="mobilehide">
            <CourseCount :countData="countData" />
          </el-col>
        </el-row>
        <!-- 课程 -->
        <el-row :gutter="16" class="row mobilehide">
          <el-col :md="24">
            <HomeTab
              v-if="queryRoot"
              :countData="countData"
              :progressCourseList="progressCourseList"
              :completedCourseList="completedCourseList"
            />
            <no-root v-else></no-root>
          </el-col>
        </el-row>
      </el-col>

      <el-col :sm="24" :md="7">
        <el-row>
          <!-- 通知公告 -->
          <Notice />
		  <div class="mobilehide">
          <!-- 学习必备 -->
          <Standby />
          <!-- 教师支持服务 -->
          <Teaching />
		  </div>
        </el-row>
      </el-col>
    </el-row>

    <keep-alive>
      <!-- 个人信息 -->
      <UserInfoTab v-if="routeSwitch === home_route.USERINFO" />

      <!-- 报税信息 -->
      <Taxations v-if="routeSwitch === home_route.TAX" />

      <!-- 规章制度 -->
      <Provisions v-if="routeSwitch === home_route.RULES" />
    </keep-alive>
  </div>
</template>

<script>
	import { COURSE_TYPE } from "@/tools/const";
import { STU_HOME_ROUTE } from "@/tools/const";
import UserInfo from "./module/UserInfo.vue";
import CourseCount from "./module/CourseCount.vue";
import HomeTab from "./module/Tab.vue";
import Notice from "./module/Notice.vue";
import Standby from "./module/Standby.vue";
import Teaching from "./module/Teaching.vue";

import UserInfoTab from "./module/user/index.vue";
import Taxations from "./module/taxations/index.vue";
import Provisions from "./module/stu_provisions/index.vue";

import { StudentCourseStat } from "@/libs/api/course";

import { GetUserMenuList } from "@/libs/api/user";
export default {
  name: "student-home",
  components: {
    UserInfo,
    CourseCount,
    HomeTab,
    Notice,
    Standby,
    Teaching,
    UserInfoTab,
    Taxations,
    Provisions,
  },
  data() {
    return {
      loading: null,
      // 课程统计
      countData: {
        progressCourseNum: 0, // 在修数
        selectedCourseNum: 0, // 已选数
        totalCourseNum: 0, // 总课程数
        completedCourseNum: 0, // 已修课程数
      },
      progressCourseList: [], // 在修课程列表
      completedCourseList: [], // 已修课程列表
	  menuList: [],
	  menuConfig: [
	    {
	      svg: "home",
	      title: "我的首页",
	      path: "/student/index",
	      name: "student-index",
	    },
	    {
	      svg: "announcement",
	      title: "通知公告",
	      path: "/student/announcement",
	      name: "announcement-index",
	      count: true,
	    },
	    {
	      svg: "plan",
	      title: "教学计划",
	      path: "/student/plan",
	      name: "plan-index",
	    },
	    {
	      svg: "money",
	      title: "学费信息",
	      path: "/student/tuition",
	      name: "tuition-index",
	    },
	    {
	      svg: "user",
	      title: "我的课程",
	      path: "/student/mycourse",
	      name: "mycourse-index",
	    },
	    {
	      svg: "notes",
	      title: "考试信息",
	      path: "/student/writeinfo",
	      name: "writeinfo-index",
	    },
	    {
	      svg: "results",
	      title: "我的成绩",
	      path: "/student/results",
	      name: "results-index",
	    },
	    {
	      svg: "file",
	      title: "文件申请",
	      path: "/student/file/apply",
	      name: "apply-index",
	    },
	    {
	      svg: "edit",
	      title: "毕业填报",
	      path: "/student/graduationEdit",
	      name: "graduationEdit-index",
	    },
	    {
	      svg: "move",
	      title: "学籍异动",
	      path: "/student/move",
	      name: "move-index",
	    },
	    {
	      svg: "degree",
	      title: "我的学位",
	      path: "/student/degree",
	      name: "degree-index",
	    },
	    {
	      svg: "money",
	      title: "个人流水",
	      path: "/student/flowingWater",
	      name: "flowingWater-index",
	    },
	    {
	      svg: "file",
	      title: "院校电子教材",
	      path: "/student/electronicTextbook",
	      name: "electronicTextbook-index",
	    },
	  ],
	  menu: [],
	  
	  courseMenu: [
	    {
	      title: "学习导航",
	      path: "/student/c/study/nav",
	      name: "c-study-nav",
	    },
	    {
	      title: "课程公告",
	      path: "/student/c/course/notice",
	      name: "c-course-notice",
	      count: true,
	    },
	    {
	      title: "课程学习",
	      path: "/student/c/course/study",
	      name: "c-course-study",
	    },
	    {
	      title: "在线答疑",
	      path: "/student/c/online/questions",
	      name: "c-online-questions",
	    },
	    {
	      title: "在线作业",
	      path: "/student/c/online/homework",
	      name: "c-online-homework",
	    },
	    {
	      title: "在线考试",
	      path: "/student/c/online/test",
	      name: "c-online-test",
	    },
	  ],
	  
	  // 毕业大作业
	  g_menu: [
	    {
	      title: "学习导航",
	      path: "/student/c/study/nav",
	      name: "c-study-nav",
	    },
	    {
	      title: "参考选题",
	      path: "/student/c/selection",
	      name: "c-selection",
	    },
	    {
	      title: "课程文件",
	      path: "/student/c/course/file",
	      name: "c-course-file",
	    },
	    {
	      title: "时间安排",
	      path: "/student/c/schedule",
	      name: "c-schedule",
	    },
	    {
	      title: "课程视频",
	      path: "/student/c/course/video",
	      name: "c-course-video",
	    },
	    {
	      title: "在线答疑",
	      path: "/student/c/online/questions",
	      name: "c-online-questions",
	    },
	    {
	      title: "撰写大作业",
	      path: "/student/c/write/big/homework",
	      name: "c-write-big-homework",
	    },
	    {
	      title: "免做申请",
	      path: "/student/c/free/apply",
	      name: "c-free-apply",
	    },
	  ],
	  
	  // 毕业设计
	  design_menu: [
	    {
	      title: "学习导航",
	      path: "/student/c/study/nav",
	      name: "c-study-nav",
	    },
	    {
	      title: "参考选题",
	      path: "/student/c/selection",
	      name: "c-selection",
	    },
	    {
	      title: "操作指引",
	      path: "/student/c/guide/oper",
	      name: "c-guide-oper",
	    },
	    {
	      title: "课程文件",
	      path: "/student/c/course/file",
	      name: "c-course-file",
	    },
	    {
	      title: "时间安排",
	      path: "/student/c/schedule",
	      name: "c-schedule",
	    },
	    {
	      title: "课程视频",
	      path: "/student/c/course/video",
	      name: "c-course-video",
	    },
	    {
	      title: "在线答疑",
	      path: "/student/c/online/questions",
	      name: "c-online-questions",
	    },
	    {
	      title: "指导教师",
	      path: "/student/c/guide/teacher",
	      name: "c-guide-teacher",
	    },
	    {
	      title: "开题报告",
	      path: "/student/c/report",
	      name: "c-report",
	    },
	    {
	      title: "提交论文",
	      path: "/student/c/submit/paper",
	      name: "c-submit-paper",
	    },
	    {
	      title: "论文终稿",
	      path: "/student/c/version/final",
	      name: "c-version-final",
	    },
	    {
	      title: "免做申请",
	      path: "/student/c/free/apply",
	      name: "c-free-apply",
	    },
	  ],
    };
  },
  computed: {
	  menuSwitch: {
	    get() {
	      return this.$store.getters.getStuMenuSwitch;
	    },
	    set(v) {
	      this.$store.dispatch("set_stu_menu_switch", v);
	    },
	  },
    routeSwitch() {
      return this.$store.getters.getStuHomeRouteSwitch;
    },
    home_route() {
      return STU_HOME_ROUTE;
    },
    // 首页课程统计
    studentCourseStat() {
      return this.$sysPerKey("eduStudentIndex:studentCourseStat");
    },
    // 学生端-我的课程-在修、已修课程列表查询
    queryRoot() {
      return this.$sysPerKey("studentCourse:querystudentCourseList");
    },
  },
  watch: {
    queryRoot(n) {
      if (n) {
        setTimeout(() => this._StudentCourseStat(), 100);
      }
    },
  },
  mounted() {
    this.queryRoot && setTimeout(() => this._StudentCourseStat(), 100);
	this.getMenu();
  },
  methods: {
	  toMenu(item){
	  		  console.log(item);
	  			  this.$router.push(item.path);
	  },
	  showmenu(){
	    		  this.isshow=!this.isshow;
	    },
	  set_menu(switchs, returns) {
	    let list;
	    if (switchs === COURSE_TYPE.C3) {
	      // 首页菜单
	      list = this.menu;
	    } else if (switchs === COURSE_TYPE.C0) {
	      // 普通课程
	      list = this.courseMenu;
	    } else if (switchs === COURSE_TYPE.C1) {
	      // 毕业论文设计
	      list = this.design_menu;
	    } else if (switchs === COURSE_TYPE.C2) {
	      // 毕业大作业
	      list = this.g_menu;
	    }
	  
	    if (returns) {
	      return list;
	    }
	    this.menuList = [];
	    this.menuList = list;
		console.log(this.menuList,"this.menuList")
	  },
	  getMenu() {
	    this.loading = true;
	    GetUserMenuList()
	      .then((res) => {
			  console.log(res,"UserMenuList")
	        const resData = res.data.find((f) => f.menuType === 2);
	        const data = resData ? resData.sysMenuVos : [];
			console.log(data,"UserMenuList data")
	        if (data.length) {
	          let menu = []; // 菜单
	          let per = []; // 菜单下的功能，控制显示隐藏
	          this.menuConfig.forEach((m) => {
	            data.forEach((item) => {
	              if (m.title === item.menuName) {
	                menu.push({
	                  ...m,
	                  sysPermissions: item.sysPermissions,
	                });
	                per.push(...item.sysPermissions);
	              }
	            });
	          });
	          this.menu = menu;
	          this.$store.dispatch("set_sys_per", per);
	          this.loading = false;
	          // 菜单切换
	          this.set_menu(this.menuSwitch);
	          const path = this.$router.currentRoute.path;
	          this.routeActive(path);
	        }
	      })
	      .catch(() => {
	        this.loading = false;
	      });
	  },
    // 首页课程
    _StudentCourseStat() {
      this.loading = true;
      StudentCourseStat()
        .then((res) => {
          const { data } = res;
          if (data) {
            this.countData = {
              progressCourseNum: data.progressCourseNum, // 在修数
              selectedCourseNum: data.selectedCourseNum, // 已选数
              totalCourseNum: data.totalCourseNum, // 总课程数
              completedCourseNum: data.completedCourseNum, // 已修课程数
            };
            this.progressCourseList = data.progressCourseList;
            this.completedCourseList = data.completedCourseList;
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
};
</script>