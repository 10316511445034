<style scoped lang="less">
.Photo {
  .items {
    width: 220px;
    display: flex;
    align-items: center;
    flex-flow: column;
    .title {
      font-size: 20px;
      color: @fontColor;
      margin: 20px 0;
      font-weight: 500;
    }
    .image-border {
      background: #f6f9fe;
      padding: 10px;
      border-radius: 4px;
    }
    .el-button {
      display: block;
      margin-top: 20px;
    }
  }
  .mgr50 {
    margin-right: 50px;
  }
  .message {
    margin-top: 20px;
    padding: 20px;
    .red {
      color: #e02020;
      font-weight: 500;
    }
  }
}
</style>
<style lang="less">
.Photo {
  .items {
    .image-border {
      .photo-avatar {
        width: 170px;
        height: 235px;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
</style>
<template>
  <div class="Photo">
    <el-row type="flex" align="middle">
      <div class="items mgr50">
        <div class="title">白色证件照</div>
        <div class="image-border">
          <el-avatar shape="square" :src="whiteImage" class="photo-avatar">
            <img
              src="https://cube.elemecdn.com/9/c2/f0ee8a3c7c9638a54940382568c9dpng.png"
            />
          </el-avatar>
        </div>
        <el-upload
          class="upload-demo"
          :action="`${url}hnjxjy-core/upload/file`"
          :headers="{
            'x-token': auth['x-token'],
            'x-perm': auth['x-perm'],
          }"
          :on-success="whiteSuccess"
          :show-file-list="false"
          accept="image/png, image/jpeg"
        >
          <el-button size="small" v-if="insertEduStudentPhoto" type="primary"
            >上传证件照</el-button
          >
        </el-upload>
        <!-- <el-button type="primary">上传证件照</el-button> -->
      </div>

      <div class="items">
        <div class="title">蓝底证件照</div>
        <div class="image-border">
          <el-avatar shape="square" :src="blueImage" class="photo-avatar">
            <img
              src="https://cube.elemecdn.com/9/c2/f0ee8a3c7c9638a54940382568c9dpng.png"
            />
          </el-avatar>
        </div>
        <el-upload
          class="upload-demo"
          :action="`${url}hnjxjy-core/upload/file`"
          :on-success="blueSuccess"
          :headers="{
            'x-token': auth['x-token'],
            'x-perm': auth['x-perm'],
          }"
          :show-file-list="false"
          accept="image/png, image/jpeg"
        >
          <el-button size="small" v-if="insertEduStudentPhoto" type="primary"
            >上传证件照</el-button
          >
        </el-upload>
        <!-- <el-button type="primary">上传证件照</el-button> -->
      </div>
    </el-row>

    <el-row class="message">
      电子证件照片要求：<br />
      1、报名照片格式：<span class="red">电子版且是jpg或png</span>;<br />
      2、报名照片尺寸要求：<span class="red">宽413像素×高626像素</span
      >，近三个月内的本人正面免冠电子证件照片;<br />
      3、报名照片底色要求：彩色(红、蓝、白底色均可)。
    </el-row>
  </div>
</template>

<script>
import { BASE_URL } from "@/libs/domain";
import {
  SelectEduStudentPhotoList,
  InsertEduStudentPhoto,
} from "@/libs/api/user";
export default {
  data() {
    return {
      whiteImage: "",
      blueImage: "",
    };
  },
  computed: {
    user() {
      return this.$store.getters.getUser;
    },
    auth() {
      return this.$store.getters.getAuth;
    },
    url() {
      return BASE_URL;
    },
    // 学生端-修改学生基本信息 上传照片
    insertEduStudentPhoto() {
      return this.$sysPerKey("eduStudentIndex:insertEduStudentPhoto");
    },
    queryRoot() {
      return this.$sysPerKey("eduStudentIndex:selectEduStudentPhotoList");
    },
  },
  watch: {
    queryRoot(n) {
      n && this.init();
    },
  },
  mounted() {
    this.queryRoot && this.init();
  },
  methods: {
    init() {
      SelectEduStudentPhotoList().then((res) => {
        if (res.data) {
          let white = res.data.filter((f) => f.photoType === "2");
          if (white.length) this.whiteImage = white[white.length - 1].photoUrl;
          let blue = res.data.filter((f) => f.photoType === "1");
          if (blue.length) this.blueImage = blue[blue.length - 1].photoUrl;
        }
      });
    },
    whiteSuccess(res) {
      if (res.code === 2000) {
        this.upload(res.data.fileUrl, 2);
      } else {
        this.$message.error(res.message || "服务器错误，请稍后重试。");
      }
    },
    blueSuccess(res) {
      if (res.code === 2000) {
        this.upload(res.data.fileUrl, 1);
      } else {
        this.$message.error(res.message || "服务器错误，请稍后重试。");
      }
    },
    upload(image, type) {
      // let data = new FormData();
      // data.append("photoUrl", image);
      // data.append("photoType", type); // 1蓝底照片 2白底照片 3证件照片 4毕业照照片
      const data = {
        photoUrl: image,
        photoType: type,
      };
      InsertEduStudentPhoto(data)
        .then((res) => {
          this.$message.success("上传成功");
          this.init();
        })
        .catch(() => {
          this.$message.error("服务器错误，请稍后重试。");
        });
    },

    readAsURL(file, imageName, type) {
      let reader = new FileReader();
      const _this = this;
      reader.onloadend = function () {
        _this[imageName] = reader.result;
        _this.upload(reader.result, type);
      };
      file && reader.readAsDataURL(file);
    },
    // 上传白底照片
    whiteUpload(req) {
      this.readAsURL(req.file, "whiteImage", 2);
    },

    // 上传蓝底照片
    blueUpload(req) {
      // this.upload(URL.createObjectURL(req.file), 1);
      this.readAsURL(req.file, "blueImage", 1);
    },
  },
};
</script>
