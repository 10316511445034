<template>
  <div class="stu_home_provisions">
    <div class="stu-module-header">
      <div class="stu-module-title">规章制度</div>
      <el-button type="primary" @click="back">返回</el-button>
    </div>
    <el-table
      :data="tableData"
      :max-height="650"
      :height="650"
      ref="provisionsTableRef"
      stripe
    >
      <el-table-column label="序号" :width="60" align="center">
        <template slot-scope="scope">
          <span>{{ scope.$index + (current - 1) * pageSize + 1 }} </span>
        </template>
      </el-table-column>
      <el-table-column
        v-for="(item, index) in columns"
        :prop="item.prop"
        :key="index"
        :label="item.label"
        align="center"
        :min-width="item.width"
      ></el-table-column>
    </el-table>
    <!-- <el-pagination
      class="pagination"
      background
      @current-change="handleCurrentChange"
      :current-page="current"
      :page-size="pageSize"
      layout="total, prev, pager, next, jumper"
      :total="tableData.length"
    >
    </el-pagination> -->
  </div>
</template>

<script>
import { STU_HOME_ROUTE } from "@/tools/const";
export default {
  name: "stu_home_provisions",
  data() {
    return {
      tableData: [],
      columns: [
        { prop: "title", label: "标题" },
        { prop: "date", label: "时间" },
      ],
      pageSize: 10,
      current: 1,
      total: 0,
    };
  },
  computed: {
    datalist() {
      return this.tableData.slice(
        (this.current - 1) * this.pageSize,
        this.current * this.pageSize
      );
    },
  },
  methods: {
    handleCurrentChange(page) {
      if (page) this.current = page;
    },

    back() {
      this.$store.dispatch("set_stu_hm_route_switch", STU_HOME_ROUTE.HOME);
    },
  },
};
</script>

<style lang="less" scoped>
.stu_home_provisions {
  background: #fff;
  padding: 20px;
  .stu-module-header {
    margin-bottom: 20px;
  }
}
</style>