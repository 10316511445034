<template>
  <div class="CourseCount">
    <ul>
      <li>
        <p class="count blue">{{ countData.totalCourseNum }}</p>
        <p class="course">总课程</p>
      </li>
      <li>
        <p class="count orange">{{ countData.selectedCourseNum }}</p>
        <p class="course">已选课程</p>
      </li>
      <li>
        <p class="count green">{{ countData.completedCourseNum }}</p>
        <p class="course">已修完课程</p>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "course-count",
  props: {
    countData: Object,
  },
};
</script>

<style scoped lang="less">
.CourseCount {
  height: 310px;
  ul {
    height: 100%;
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    li {
      background: #fff;
      height: 90px;
      line-height: 90px;
      text-align: center;
      box-shadow: @shadow;
      p {
        vertical-align: bottom;
        display: inline-block;
      }
      .count {
        font-size: 48px;
        font-weight: 500;
      }
      .blue {
        color: @mainColor;
      }
      .orange {
        color: #f19844;
      }
      .green {
        color: #5eb95e;
      }
      .course {
        color: #999;
        font-weight: bold;
        font-size: 17px;
        margin-left: 8px;
        height: 70px;
        line-height: 70px;
      }
    }
    li:last-child {
      margin-bottom: 0;
    }
  }
}
</style>