
<style scoped lang="less">
.Student-UpdatePwd {
  margin-top: 30px;
  .el-input {
    width: 300px;
  }

  .msg {
    display: inline-block;
    margin-left: 10px;
    color: #666;
  }
}
</style>

<template>
  <div class="Student-UpdatePwd">
    <el-form
      ref="updatePwdForm"
      :model="formData"
      :rules="rules"
      size="medium"
      label-width="100px"
    >
      <el-form-item label="旧密码" prop="loginPassword">
        <el-input
          v-model="formData.loginPassword"
          placeholder="请输入旧密码"
          clearable
          show-password
        ></el-input>
      </el-form-item>
      <el-form-item label="新密码" prop="newLoginPassword1">
        <el-input
          v-model="formData.newLoginPassword1"
          placeholder="请输入新密码"
          clearable
          show-password
        ></el-input>
        <span class="msg"
          >例：学院名称简写开头，实例(山东师范大学+身份证后6位+特殊字符):Sdsf01210012#</span
        >
      </el-form-item>
      <el-form-item label="确认密码" prop="newLoginPassword2">
        <el-input
          v-model="formData.newLoginPassword2"
          placeholder="请确认密码"
          clearable
          show-password
        ></el-input>
      </el-form-item>
      <el-form-item size="large">
        <el-button type="primary" @click="submitForm" :loading="loading">
          提交
        </el-button>
        <el-button @click="resetForm">重置</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import { REG } from "@/tools/const";
import { UploadLoginPassword } from "@/libs/api/user";
export default {
  name: "student-updatePwd",
  components: {},
  props: [],
  data() {
    return {
      loading: false,
      formData: {
        loginId: null,
        loginPassword: null,
        newLoginPassword1: null,
        newLoginPassword2: null,
      },
      rules: {
        loginPassword: [
          {
            required: true,
            message: "请输入旧密码",
            trigger: "blur",
          },
        ],
        newLoginPassword1: [
          {
            required: true,
            message: "请输入新密码",
            trigger: "blur",
          },
          {
            pattern: REG.PASSWORD,
            message: "密码长度为10个字符，需包含大小写字母，数字，特殊字符",
            trigger: "blur",
          },
        ],
        newLoginPassword2: [
          {
            required: true,
            message: "请输入确认密码",
            trigger: "blur",
          },
        ],
      },
    };
  },
  computed: {
    user() {
      return this.$store.getters.getUser;
    },
  },
  watch: {},
  created() {},
  mounted() {},
  methods: {
    submitForm() {
      this.$refs["updatePwdForm"].validate((valid) => {
        if (!valid) return;
        // TODO 提交表单
        this.formData.loginId = this.user.loginId;
        if (this.formData.newLoginPassword1 !== this.formData.newLoginPassword2){
          this.$message.success("两次输入的密码不一致!");
          return;
        }
        this.loading = true;
        UploadLoginPassword(this.formData)
          .then((res) => {
            if (res.code === 2000){
              this.$message.success("密码更新成功，请重新登录");
              this.loading = false;
              localStorage.clear();
              this.$router.push("/Online/index");
            }
          })
          .catch(() => {
            this.loading = false;
          });
      });
    },
    resetForm() {
      this.$refs["updatePwdForm"].resetFields();
    },
  },
};
</script>