
<style scoped lang="less">
.Home-Tab {
  background: #fff;
  box-shadow: @shadow;

  .content-group {
    overflow-y: auto;
    height: 520px;
    .content {
      border-top: 1px solid #eee;
      padding: 20px;
      //   width: 100%;
      display: flex;
      align-items: center;
      overflow: hidden;
      .image {
        width: 120px;
        height: 100px;
        position: relative;
        .image-pos {
          width: 120px;
          height: 100px;
          position: absolute;
          top: 0;
          left: 0;
          overflow: hidden;
          .image-tag {
            color: #fff;
            font-size: 12px;
            text-align: center;
            position: absolute;
            top: 8px;
            left: -18px;
            height: 20px;
            line-height: 20px;
            width: 70px;
            transform: rotate(-45deg);
            -ms-transform: rotate(-45deg); /* IE 9 */
            -moz-transform: rotate(-45deg); /* Firefox */
            -webkit-transform: rotate(-45deg); /* Safari 和 Chrome */
            -o-transform: rotate(-45deg); /* Opera */
          }
          .image-blue {
            background: @mainColor;
          }
          .image-red {
            background: #e8302e;
          }
          .image-green {
            background: #06bf6a;
          }
        }

        .image-none {
          width: 90px;
          height: 65px;
          margin: 15% auto;
        }
        .image-none-text {
          position: absolute;
          bottom: 0;
          text-align: center;
          width: 100%;
          height: 20px;
          line-height: 1;
        }
      }
      .info {
        width: 100%;
        margin-left: 8px;
        li:not(:last-child) {
          display: flex;
          align-items: center;
          p {
            font-weight: bold;
          }
          .tag {
            background: #e4f0ff;
            padding: 3px 6px;
            text-align: center;
            border-radius: 4px;
            color: #307ef3;
            font-size: 14px;
            font-weight: bold;
            margin-right: 5px;
          }
          .course-name {
            font-size: 17px;
          }
          .count {
            margin: 2px 0 4px;
            font-size: 15px;
            color: #999;
            span {
              color: rgb(241, 152, 68);
              font-weight: bold;
            }
          }
          .count:nth-child(1) {
            padding-right: 15px;
            border-right: 1px solid rgb(204, 204, 204);
          }
          .count:nth-child(2) {
            padding-left: 15px;
          }
        }
        .stu-progress-li {
          display: flex;
          align-items: flex-end;
          .pro {
            width: 220px;
            p {
              color: #999;
              font-weight: bold;
              font-size: 13px;
              margin-bottom: 2px;
            }
          }

          .btn-group {
            margin-left: 50px;
          }
        }

        .none-item {
          width: 220px;
          height: 22px;
        }
      }
    }
    .svg-no-data {
      width: 100%;
      height: 200px;
      margin-top: 10%;
      .svg-message {
        text-align: center;
        font-size: 18px;
        font-weight: 500;
        padding-left: 5%;
        margin-top: 20px;
      }
    }
  }
}
</style>
<style lang="less">
.Home-Tab {
  .content {
    .btn-group {
      .el-button {
        span {
          font-weight: bold;
        }
      }
    }
  }
}
</style>
<template>
  <div class="Home-Tab">
    <el-tabs v-model="activeName" @tab-click="handleClick" class="el-tabs-icon">
      <el-tab-pane v-for="(item, index) in tab" :key="index" :name="item.name">
        <span slot="label">
          <i :class="item.icon"></i>
          {{ `${item.label} (${item.count})` }}
        </span>
      </el-tab-pane>
    </el-tabs>
    <div class="content-group">
      <template v-if="data.length > 0">
        <div class="content" v-for="(item, index) in data" :key="index">
          <div class="image">
            <div class="image-pos">
              <div class="image-tag" :class="bgORtext(item.imageTag)">
                {{ bgORtext(item.imageTag, "text") }}
              </div>
            </div>
            <el-avatar
              shape="square"
              :src="item.coverUrl"
              @error="errorHandler"
              :style="{ width: '120px', height: '100%' }"
            >
              <img class="image-none" src="../../../../assets/logo_top.png" />
              <!-- <p class="image-none-text">暂无图片</p> -->
              <!-- src="https://cube.elemecdn.com/e/fd/0fc7d20532fdaf769a25683617711png.png" -->
            </el-avatar>
          </div>
          <ul class="info">
            <li>
              <p class="tag">第{{ item.semester }}学期</p>
              <p class="course-name">{{ item.courseName }}</p>
            </li>
            <li>
              <p class="count" v-if="item.workOnline >= 0 || item.workOnline">
                在线作业:<span>{{ item.workOnline }}</span>
              </p>
              <div v-else class="none-item"></div>
              <p class="count" v-if="item.examOnline >= 0 || item.examOnline">
                在线考试:<span>{{ item.examOnline }}</span>
              </p>
              <div v-else class="none-item"></div>
            </li>
            <li class="stu-progress-li">
              <div
                class="pro"
                v-if="item.learnProgress >= 0 || item.learnProgress"
              >
                <p>学习进度 {{ stuProgress(item.learnProgress) }}%</p>
                <el-progress
                  :percentage="stuProgress(item.learnProgress)"
                  :show-text="false"
                ></el-progress>
              </div>
              <div v-else class="none-item"></div>
              <div
                class="btn-group"
                v-if="item.courseType === Number(ctype.C0)"
              >
                <el-button type="primary" @click="onCourse(item, index)">
                  课程学习
                </el-button>
                <el-button type="primary" @click="onQuestions(item, index)">
                  在线答疑
                </el-button>
                <el-button type="primary" @click="onHomeWork(item, index)">
                  在线作业
                </el-button>
                <el-button type="primary" @click="onTheTest(item, index)">
                  在线考试
                </el-button>
              </div>
              <div
                class="btn-group"
                v-if="item.courseType === Number(ctype.C2)"
              >
                <el-button type="primary" @click="onSelection(item, index)">
                  参选考题
                </el-button>
                <el-button type="primary" @click="onSchedule(item, index)">
                  时间安排
                </el-button>
                <el-button type="primary" @click="onQuestions(item, index)">
                  在线答疑
                </el-button>
                <el-button
                  type="primary"
                  @click="onWriteBigHomework(item, index)"
                >
                  撰写大作业
                </el-button>
              </div>
              <div
                class="btn-group"
                v-if="item.courseType === Number(ctype.C1)"
              >
                <!-- 毕业论文设计 -->
                <el-button type="primary" @click="onReport(item, index)">
                  开题报告
                </el-button>
                <el-button type="primary" @click="onSubmitPaper(item, index)">
                  提交论文
                </el-button>
                <el-button type="primary" @click="onVersionFinal(item, index)">
                  论文终稿
                </el-button>
              </div>
            </li>
          </ul>
        </div>
      </template>
      <div v-else class="svg-no-data">
        <svg-icon name="no-data"></svg-icon>
        <div class="svg-message">暂无课程信息</div>
      </div>
    </div>
  </div>
</template>

<script>
import { COURSE_TYPE } from "@/tools/const";
import { QueryStudentCourseList } from "@/libs/api/course";
export default {
  name: "home-tab",
  props: {
    // countData: Object,
    // progressCourseList: Array,
    // completedCourseList: Array,
  },
  data() {
    return {
      activeName: "one",

      data: [],
      progressCourseNum: 0,
      completedCourseNum: 0,
      progressCourseList: [],
      completedCourseList: [],
    };
  },
  computed: {
    bgORtext() {
      return (tag, t) => {
        if (tag === 1) return t ? "学习中" : "image-blue";
        if (tag === 2) return t ? "已通过" : "image-green";
        if (tag === 3) return t ? "未通过" : "image-red";
      };
    },
    tab() {
      let arr = [
        {
          label: "在修课程",
          count: this.progressCourseNum,
          name: "one",
          icon: "el-icon-time",
        },
        {
          label: "已修课程",
          count: this.completedCourseNum,
          name: "two",
          icon: "el-icon-circle-check",
        },
      ];
      return arr;
    },
    ctype() {
      return COURSE_TYPE;
    },
    // 学生端-我的课程-在修、已修课程列表查询
    queryRoot() {
      return this.$sysPerKey("studentCourse:querystudentCourseList");
    },
    // 学习进度
    stuProgress() {
      return (val) => {
        if (val) {
          return Number((val * 100).toFixed(2));
        }
        return val;
      };
    },
  },
  watch: {
    queryRoot(n) {
      if (n) {
        this.init();
      }
    },
  },
  mounted() {
    this.queryRoot && this.init();
  },
  methods: {
    init() {
      QueryStudentCourseList().then((res) => {
        if (res && res.data) {
          const { data } = res;
          // 在修
          this.progressCourseNum = data.progressCourseNum;
          this.progressCourseList = data.progressCourseList;
          // 已修
          this.completedCourseNum = data.completedCourseNum;
          this.completedCourseList = data.completedCourseList;
          // 默认显示在修课程
          this.data = [...this.progressCourseList];
        }
      });
    },
    handleClick(tab) {
      if (tab.name === "one") {
        this.data = [...this.progressCourseList];
      } else if (tab.name === "two") {
        this.data = [...this.completedCourseList];
      }
    },
    errorHandler() {
      return true;
    },
    // 普通课程---
    // 课程学习
    onCourse(row, index) {
      this.routeSwitch(row, "course/study");
    },
    // 在线答疑
    onQuestions(row, index) {
      this.routeSwitch(row, "online/questions");
    },
    // 在线作业
    onHomeWork(row, index) {
      this.routeSwitch(row, "online/homework");
    },
    // 在线考试
    onTheTest(row, index) {
      this.routeSwitch(row, "online/test");
    },
    // 毕业大作业---
    // 参选考题
    onSelection(row, index) {
      this.routeSwitch(row, "selection");
    },
    // 时间安排
    onSchedule(row, index) {
      this.routeSwitch(row, "schedule");
    },
    // 撰写大作业
    onWriteBigHomework(row, index) {
      this.routeSwitch(row, "write/big/homework");
    },
    // 毕业论文（设计）---
    // 开题报告
    onReport(row, index) {
      this.routeSwitch(row, "report");
    },
    // 提交论文
    onSubmitPaper(row, index) {
      this.routeSwitch(row, "submit/paper");
    },
    // 论文终稿
    onVersionFinal(row, index) {
      this.routeSwitch(row, "version/final");
    },

    routeSwitch(data, path) {
      if (path && path[0] === "/") {
        path = path.substring(1, path.length);
      }
      let num;
      // 菜单切换
      const ctype = data.courseType + "";
      // 毕业大作业
      if (ctype === COURSE_TYPE.C2) {
        num = COURSE_TYPE.C2;
      } else if (ctype === COURSE_TYPE.C1) {
        // 毕业论文（设计）
        num = COURSE_TYPE.C1;
      } else {
        // 普通课程
        num = COURSE_TYPE.C0;
      }
      this.$store.dispatch("set_stu_menu_switch", num);
      this.$router.push(`/student/c/${path}`);

      data && this.$store.dispatch("set_stu_mycourse", data);
    },
  },
};
</script>
