
<style scoped lang="less">
.Notice {
  padding: 20px;
  margin-bottom: 20px;
  background: #fff;
  box-shadow: @shadow;
  .stu-module-header {
    padding-bottom: 10px;
    border-bottom: 1px solid #f7faff;
    .more {
      font-size: 15px;
      font-weight: bold;
      color: #999;
      cursor: pointer;
    }
  }
  .notice-list {
    height: 330px;
    overflow-y: auto;
    li {
      padding: 8px 0;
      border-bottom: 1px dashed #ccc;

      .title-group {
        display: flex;
        align-items: center;
        .svg-border {
          background: @mainColor;
          margin-right: 10px;
          width: 36px;
          height: 20px;
          .svg-div {
            width: 24px;
            height: 100%;
            color: #fff;
            margin: 0 auto;
          }
        }
        .title {
          font-weight: bold;
          cursor: pointer;
          &:hover {
            color: @mainColor;
          }
        }
      }
      .date {
        padding-left: 46px;
        font-size: 13px;
        color: #999;
        margin-top: 5px;
      }
    }
  }
}
</style>
<template>
  <div class="Notice">
    <div class="stu-module-header">
      <div class="stu-module-title">通知公告</div>
      <div class="more" @click="more">更多></div>
    </div>
    <ul class="notice-list">
      <li v-for="(item, index) in notice" :key="index">
        <div class="title-group">
          <div class="svg-border">
            <div class="svg-div">
              <svg-icon name="notice"></svg-icon>
            </div>
          </div>
          <div class="title" @click="onNotice(item, index)">
            {{ item.title }}
          </div>
        </div>
        <div class="date">
          {{ item.createTime }}
        </div>
      </li>
    </ul>
    <el-dialog :visible.sync="dialog" width="40%" :title="items.title">
      <div v-loading="loading" style="min-height: 50px;">
        <div style="overflow: auto; max-height: 300px" v-html="items.content">
        </div>
        <div
          style="font-size: 14px; color: #333; margin-top: 20px; float: right"
        >
          {{ items.createTime }}
        </div>
        <div style="clear: both"></div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  QueryAllNoticeByStudent,
  UpdateNoticeByNoticeIdAndStudentId,
} from "@/libs/api/student";
export default {
  name: "notice",
  data() {
    return {
      notice: [],
      items: {},
      dialog: false,
      loading: false,
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    onNotice(row, index) {
      // this.items = row;
      if (row) {
        this.loading = true;
        this.dialog = true;
        UpdateNoticeByNoticeIdAndStudentId({ noticeId: row.id })
          .then((res) => {
            this.loading = false;
            if (res.data) {
              this.items = res.data;
            }
          })
          .catch(() => {
            this.loading = false;
          });
      }
    },
    more() {
      this.$router.push("announcement");
    },
    init() {
      QueryAllNoticeByStudent({
        page: 1,
        pageSize: 10,
      }).then((res) => {
        this.notice = res.data.list;
      });
    },
  },
};
</script>
