var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"Photo"},[_c('el-row',{attrs:{"type":"flex","align":"middle"}},[_c('div',{staticClass:"items mgr50"},[_c('div',{staticClass:"title"},[_vm._v("白色证件照")]),_c('div',{staticClass:"image-border"},[_c('el-avatar',{staticClass:"photo-avatar",attrs:{"shape":"square","src":_vm.whiteImage}},[_c('img',{attrs:{"src":"https://cube.elemecdn.com/9/c2/f0ee8a3c7c9638a54940382568c9dpng.png"}})])],1),_c('el-upload',{staticClass:"upload-demo",attrs:{"action":`${_vm.url}hnjxjy-core/upload/file`,"headers":{
          'x-token': _vm.auth['x-token'],
          'x-perm': _vm.auth['x-perm'],
        },"on-success":_vm.whiteSuccess,"show-file-list":false,"accept":"image/png, image/jpeg"}},[(_vm.insertEduStudentPhoto)?_c('el-button',{attrs:{"size":"small","type":"primary"}},[_vm._v("上传证件照")]):_vm._e()],1)],1),_c('div',{staticClass:"items"},[_c('div',{staticClass:"title"},[_vm._v("蓝底证件照")]),_c('div',{staticClass:"image-border"},[_c('el-avatar',{staticClass:"photo-avatar",attrs:{"shape":"square","src":_vm.blueImage}},[_c('img',{attrs:{"src":"https://cube.elemecdn.com/9/c2/f0ee8a3c7c9638a54940382568c9dpng.png"}})])],1),_c('el-upload',{staticClass:"upload-demo",attrs:{"action":`${_vm.url}hnjxjy-core/upload/file`,"on-success":_vm.blueSuccess,"headers":{
          'x-token': _vm.auth['x-token'],
          'x-perm': _vm.auth['x-perm'],
        },"show-file-list":false,"accept":"image/png, image/jpeg"}},[(_vm.insertEduStudentPhoto)?_c('el-button',{attrs:{"size":"small","type":"primary"}},[_vm._v("上传证件照")]):_vm._e()],1)],1)]),_c('el-row',{staticClass:"message"},[_vm._v(" 电子证件照片要求："),_c('br'),_vm._v(" 1、报名照片格式："),_c('span',{staticClass:"red"},[_vm._v("电子版且是jpg或png")]),_vm._v(";"),_c('br'),_vm._v(" 2、报名照片尺寸要求："),_c('span',{staticClass:"red"},[_vm._v("宽413像素×高626像素")]),_vm._v("，近三个月内的本人正面免冠电子证件照片;"),_c('br'),_vm._v(" 3、报名照片底色要求：彩色(红、蓝、白底色均可)。 ")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }