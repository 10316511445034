
<style scoped lang="less">
.Home-UserInfo {
  height: 310px;
  background: #fff;
  box-shadow: @shadow;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  .user-info {
    width: 80%;
    margin-right: 20px;
    .user-top {
      padding-bottom: 20px;
      border-bottom: 1px dashed #ccc;
      display: flex;
      align-items: center;
      span {
        font-weight: bold;
      }
      .head {
        min-width: 105px;
        max-width: 105px;
        height: 132px;
      }
      .info {
        width: 100%;
        min-width: 100px;
        margin-left: 15px;
        > div {
          margin-bottom: 15px;
          font-weight: bold;
        }
        .username {
          font-size: 22px;
          display: inline-flex;
          align-items: center;
          .tag {
            margin-left: 16px;
            font-size: 12px;
            padding: 3px;
            border-radius: 4px;
            border: 1px solid @mainColor;
            color: @mainColor;
          }
        }
        .stuNo {
          font-size: 14px;
          color: #999;
        }
        .more {
          font-size: 15px;
          span:first-child {
            display: inline-block;
            border-right: 1px solid rgb(59, 69, 83);
            padding-right: 5px;
          }
          span:nth-child(2) {
            display: inline-block;
            padding-left: 5px;
          }
        }
      }
    }

    .user-bottom {
      padding-top: 20px;
      p {
        color: #999;
        font-weight: bold;
        font-size: 15px;
        height: 25px;
        line-height: 25px;
        span {
          font-weight: bold;
          color: #3b4553;
        }
      }
    }
  }
  .info-btn {
    li {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 148px;
      height: 50px;
      margin-bottom: 28px;
      cursor: pointer;
      > div {
        font-weight: bold;
        font-size: 16px;
      }
      .svg-group {
        width: 30px;
        height: 30px;
        line-height: 30px;
        border-radius: 4px;
        margin-right: 10px;
        .svg-div {
          margin: 0 auto;
          width: 18px;
          height: 18px;
        }
      }
      .blue {
        background-image: linear-gradient(#6bc1ff, #0887e7);
      }
      .orange {
        background-image: linear-gradient(#f6bf7b, #fc9d29);
      }
      .purple {
        background-image: linear-gradient(#a389fe, #6338ff);
      }
    }
    li:first-child {
      background: rgba(48, 126, 243, 0.07);
    }
    li:nth-child(2) {
      background: rgba(253, 154, 35, 0.07);
    }
    li:last-child {
      background: rgba(101, 58, 255, 0.05);
    }
  }

  @media screen and (min-width: 1366px) {
    padding: 0 50px 0 30px;
    justify-content: center;
    .user-info {
      width: 50%;
      margin-right: 100px;
    }
    .info-btn {
      li {
        width: 188px;
        height: 60px;
        > div {
          font-size: 18px;
        }
      }
    }
  }
}
</style>
<template>
  <div class="Home-UserInfo">
    <div class="user-info" v-if="user">
      <div class="user-top">
        <div class="head">
          <!--头像 -->
          <el-avatar
            shape="square"
            :style="{ width: '100%', height: '100%' }"
            :src="user.headerImg || ''"
          >
            <img
              :style="{ width: '100%', height: '100%' }"
              src="https://cube.elemecdn.com/9/c2/f0ee8a3c7c9638a54940382568c9dpng.png"
            />
          </el-avatar>
        </div>
        <div class="info">
          <div class="username">
            <!-- 学生姓名 -->
            <span>{{ user.name || "--" }}</span>
            <!-- 专业 -->
            <span class="tag">{{ userItem.stageName }}</span>
          </div>
          <div class="stuNo">学号：{{ user.studentNo }}</div>
          <div class="more">
            <!-- 专业 -->
            <span>{{ userItem.profName || "--" }}</span>
            <!-- 2020级 -->
            <span>{{ userItem.gradeName || "--" }}</span>
          </div>
        </div>
      </div>
      <div class="user-bottom">
        <p>
          函授站：<span>{{ userItem.siteName }}</span>
        </p>
        <p>
          联系电话：<span>{{ userItem.contactPhone }}</span>
        </p>
      </div>
    </div>

    <ul class="info-btn mobilehide">
      <li @click="handleItems('userinfo')">
        <div class="svg-group blue">
          <div class="svg-div">
            <svg-icon name="user-btn"></svg-icon>
          </div>
        </div>
        <div>个人信息</div>
      </li>
      <li @click="handleItems()">
        <div class="svg-group orange">
          <div class="svg-div">
            <svg-icon name="money-btn"></svg-icon>
          </div>
        </div>
        <div>学费信息</div>
      </li>
      <!-- <li @click="handleItems('tax')">
        <div class="svg-group purple">
          <div class="svg-div">
            <svg-icon name="tax-btn"></svg-icon>
          </div>
        </div>
        <div>报税信息</div>
      </li> -->
    </ul>
  </div>
</template>

<script>
import { STU_HOME_ROUTE } from "@/tools/const";
import { GetStudentInfo } from "@/libs/api/user";
export default {
  name: "home-userInfo",
  data() {
    return {
      userItem: {
        stageName: "-", // 本考研
        profName: "-", // 专业名称
        gradeName: "-", // 年级
        contactPhone: "", // 电话
      },
    };
  },
  computed: {
    user() {
      return this.$store.getters.getUser;
    },
  },
  mounted() {
    GetStudentInfo().then((res) => {
      const { data } = res;
      this.userItem = data;
    });
  },
  methods: {
    handleItems(flag) {
      if (flag) {
        let route;
        if (flag === "userinfo") {
          route = STU_HOME_ROUTE.USERINFO;
        } else if (flag === "tax") {
          route = STU_HOME_ROUTE.TAX;
        }
        this.$store.dispatch("set_stu_hm_route_switch", route);
      } else {
        this.$router.push("/student/tuition");
      }
    },
  },
};
</script>
