<style scoped lang="less">
.BasicInfo {
  .edit {
    position: absolute;
    top: 12px;
    right: 90px;
    z-index: 1;
  }
  .group {
    border-top: 1px solid rgb(238, 238, 238);
    margin-top: 20px;
    .title {
      margin-top: 20px;
      padding: 10px 0;
      font-size: 16px;
      font-weight: bold;
      color: @mainColor;
    }
    .items {
      padding: 10px 0;
      .el-col {
        // width: 33.33%;
        display: inline-flex;
        align-items: center;
        padding: 10px 0;
        height: 40px;
        div {
          font-weight: 500;
        }
        .label {
          width: 125px;
          min-width: 80px;
          text-align: right;
          color: #adadad;
        }
        .value {
          padding-left: 4px;
        }
      }
    }
  }
}
</style>
<template>
  <div class="BasicInfo" v-loading="loading">
    <el-button
      type="primary"
      size="small"
      class="edit"
      v-if="updateStudentInfo"
      @click="updateInfo"
    >
      修改信息
    </el-button>
    <div class="group">
      <div class="title">个人信息</div>
      <el-row class="items">
        <el-col
          :sm="12"
          :md="12"
          :lg="8"
          v-for="(item, index) in basic"
          :key="index"
        >
          <div class="label">{{ item.label }}：</div>
          <div class="value">{{ item.value }}</div>
        </el-col>
      </el-row>
    </div>
    <div class="group">
      <div class="title">学籍信息</div>
      <el-row class="items">
        <el-col
          :sm="12"
          :md="12"
          :lg="8"
          v-for="(item, index) in study"
          :key="index"
        >
          <div class="label">{{ item.label }}：</div>
          <div class="value">
            <template>
              {{ item.value }}
              <el-button
                v-if="item.button"
                size="mini"
                style="margin-left: 4px"
                :loading="btnload"
                @click="item.handle"
                type="primary"
                >确认已自查</el-button
              >
            </template>
          </div>
        </el-col>
      </el-row>
    </div>
    <div class="group">
      <div class="title">奖罚信息</div>
      <el-row class="items">
        <el-col
          :sm="12"
          :md="12"
          :lg="8"
          v-for="(item, index) in reward"
          :key="index"
        >
          <div class="label">{{ item.label }}：</div>
          <div class="value">{{ item.value }}</div>
        </el-col>
      </el-row>
    </div>
    <div class="group">
      <div class="title">其他</div>
      <el-row class="items">
        <el-col
          :sm="12"
          :md="12"
          :lg="8"
          v-for="(item, index) in other"
          :key="index"
        >
          <div class="label">{{ item.label }}：</div>
          <div class="value">{{ item.value }}</div>
        </el-col>
      </el-row>
    </div>

    <el-dialog title="个人信息修改" :visible.sync="dialog" width="30%">
      <el-form
        :model="form"
        label-width="100px"
        :rules="rules"
        ref="uInfoRefForm"
      >
        <el-form-item label="姓名">
          <span>{{ form.name }}</span>
        </el-form-item>
        <el-form-item label="学号">
          <span>{{ form.studentNo }}</span>
        </el-form-item>
        <el-form-item label="姓名拼音" prop="namePinYin">
          <el-input
            v-model="form.namePinYin"
            placeholder="请输入"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="移动电话" prop="contactPhone">
          <el-input
            v-model="form.contactPhone"
            placeholder="请输入"
            clearable
            maxlength="11"
            show-word-limit
          ></el-input>
        </el-form-item>
        <el-form-item label="电子邮箱" prop="email">
          <el-input
            v-model="form.email"
            placeholder="请输入"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="通信地址" prop="contactAddr">
          <el-input
            type="textarea"
            placeholder="请输入内容"
            v-model="form.contactAddr"
            maxlength="50"
            show-word-limit
            clearable
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="dialog = false">取 消</el-button>
        <el-button
          size="small"
          type="primary"
          :loading="btnLoading"
          @click="confirm('uInfoRefForm')"
          >确 认</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { REG } from "@/tools/const";
import { dateFormatter } from "@/tools/date";
import { GetStudentInfo, UpdateStudentInfo } from "@/libs/api/user";
export default {
  name: "basic-info",
  data() {
    return {
      loading: false,
      btnload: false,
      btnLoading: false,
      dialog: false,
      basic: [
        { label: "姓名", code: "name", value: "" },
        { label: "姓名拼音", code: "namePinYin", value: "" },
        { label: "民族", code: "clan", value: "" },
        { label: "证件类型", code: "certificateType", value: "" },
        { label: "性别", code: "sex", value: "" },
        { label: "政治面貌", code: "politicalStatus", value: "" },
        { label: "身份证号", code: "certificateNo", value: "" },
        { label: "出生日期", code: "birthday", value: "" },
        { label: "文化程度", code: "education", value: "" },
      ],

      study: [
        { label: "函授站", code: "siteName", value: "" },
        { label: "联系方式", code: "contactPhone", value: "" },
        { label: "入学时间", code: "entranceTime", value: null },
        { label: "层次", code: "stageName", value: "" },
        { label: "录取时间", code: "matchTime", value: null },
        { label: "学籍状态", code: "rollStatus", value: "" },
        { label: "年级", code: "gradeName", value: "" },
        { label: "专业", code: "profName", value: "" },
        { label: "电子学号", code: "studentNo", value: "" },
        {
          label: "学籍自查状态",
          code: "selfExamination",
          value: "",
          handle: () => this.setSelfExamination(),
        },
      ],

      reward: [
        { label: "奖励", code: "rewardList", value: "" },
        { label: "处分", code: "punishmentList", value: "" },
      ],

      other: [
        { label: "通信地址", code: "contactAddr", value: "" },
        { label: "移动电话", code: "contactPhone", value: "" },
        { label: "固定电话", code: "contactPhone", value: "" },
        { label: "电子邮箱", code: "email", value: "" },
        { label: "工作单位", code: "workUnit", value: "" },
      ],

      form: {
        name: "",
        studentNo: "",
        namePinYin: "",
        contactPhone: "",
        email: "",
        contactAddr: "",
      },
      rules: {
        namePinYin: [
          {
            required: true,
            min: 2,
            message: "最小不得小于2位字符",
            trigger: "blur",
          },
          { pattern: REG.ONLYLETTER, message: "只能录入字母", trigger: "blur" },
        ],
        contactPhone: [
          {
            required: true,
            message: "请输入手机号",
            trigger: "blur",
          },
          {
            min: 11,
            max: 11,
            pattern: REG.MOBILE,
            message: "手机号格式应为11位数字",
            trigger: "blur",
          },
        ],
        email: [
          {
            pattern: REG.EMAIL,
            message: "请输入正确的邮箱地址",
            trigger: "blur",
          },
        ],
        contactAddr: [
          {
            required: true,
            message: "请输入通信地址",
            trigger: "blur",
          },
        ],
      },
    };
  },
  computed: {
    user() {
      return this.$store.getters.getUser;
    },
    updateStudentInfo() {
      return this.$sysPerKey("eduStudentIndex:updateStudentInfo");
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    edit() {},
    init() {
      this.loading = true;
      GetStudentInfo()
        .then((res) => {
          const { data } = res;
          for (const key in data) {
            this.basic.forEach((el, index) => {
              if (el.code === key) {
                el.value = key.toLowerCase().includes("birthday")
                  ? dateFormatter(data[key])
                  : data[key];
              }
            });
            this.study.forEach((el, index) => {
              if (el.code === key) {
                el.value = key.toLowerCase().includes("time")
                  ? dateFormatter(data[key])
                  : data[key];
              }
              if (el.code === "selfExamination" && key === "selfExamination") {
                if (data[key] === 0) {
                  el.button = true;
                  el.value = "未自查";
                } else {
                  el.button = false;
                  el.value = "已自查";
                }
              }
            });
            this.other.forEach((el, index) => {
              if (el.code === key) {
                el.value = data[key];
              }
            });
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    setSelfExamination() {
      this.btnload = true;
      UpdateStudentInfo({ selfExamination: 1 })
        .then((res) => {
          this.btnload = false;
          this.init();
        })
        .catch(() => {
          this.btnload = false;
        });
    },
    updateInfo() {
      this.dialog = true;
      const { name, studentNo, contactPhone, email, namePinYin, contactAddr } =
        this.user;
      this.form = {
        name,
        studentNo,
        contactPhone,
        email,
        namePinYin,
        contactAddr,
      };
    },
    confirm(refName) {
      this.$refs[refName].validate((valid) => {
        if (!valid) return false;
        this.btnLoading = true;
        UpdateStudentInfo(this.form)
          .then((res) => {
            this.dialog = false;
            let user = {
              ...this.user,
              ...this.form,
            };
            // 修改存储的用户信息
            this.$store.dispatch("set_user", user);
            this.$message.success("信息修改成功");
            this.btnLoading = false;
            this.init();
          })
          .catch(() => {
            this.btnLoading = false;
          });
      });
    },
  },
};
</script>