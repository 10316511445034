<style scoped lang="less">
.Teaching {
  background: #fff;
  box-shadow: @shadow;
  padding: 20px;
  .stu-module-header {
    padding-bottom: 10px;
    border-bottom: 1px solid #f7faff;
  }
  .service-list {
    margin-top: 10px;
    li {
      position: relative;
      padding-left: 20px;
      margin-bottom: 5px;
      cursor: pointer;
      font-size: 18px;
      display: inline-block;
      width: 40%;
      &::before {
        content: '';
        position: absolute;
        top: 10px;
        left: 0;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background: #97bef9;
      }
      &:hover {
        color: @mainColor;
      }
    }
  }
}
</style>
<template>
  <div class="Teaching">
    <div class="stu-module-header">
      <div class="stu-module-title">教师支持服务</div>
    </div>
    <ul class="service-list">
      <li>自习室</li>
      <li>网上图书馆</li>
      <li>留言板</li>
      <li>校园论坛</li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "teaching",
  data() {
    return {};
  },
  methods: {},
};
</script>