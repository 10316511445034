<style lang="less">
.userInfo-tab {
  background: #fff;
  box-shadow: @shadow;
  position: relative;
  min-width: 768px;
  .back {
    position: absolute;
    top: 12px;
    right: 20px;
    z-index: 1;
  }
  .el-tabs__content {
    overflow: unset;
    position: unset;
  }
}
</style>
<template>
  <div class="userInfo-tab">
    <el-button type="primary" size="small" class="back" @click="back"
      >返回</el-button
    >
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="基本信息" name="basicInfo">
        <basic-info />
      </el-tab-pane>
      <el-tab-pane label="照片" name="photo" v-if="queryRoot">
        <Photo />
      </el-tab-pane>
      <el-tab-pane label="账号安全" name="account">
        <UpdatePwd />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import { STU_HOME_ROUTE } from "@/tools/const";
import BasicInfo from "./module/BasicInfo.vue";
import Photo from "./module/Photo.vue";
import UpdatePwd from "./module/UpdatePwd.vue";
export default {
  name: "userInfo-tab",
  components: { BasicInfo, Photo, UpdatePwd },
  data() {
    return {
      activeName: "basicInfo",
    };
  },
  computed: {
    queryRoot() {
      return this.$sysPerKey("eduStudentIndex:selectEduStudentPhotoList");
    },
  },
  methods: {
    handleClick(tab) {
    },

    back() {
      this.$store.dispatch("set_stu_hm_route_switch", STU_HOME_ROUTE.HOME);
    },
  },
};
</script>
